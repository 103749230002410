import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../componentCss/webform.css";
import { Link, useParams } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import AssignedFormSidebar from "./assignedFormSidebar";
import "../componentCss/sidebar.css";
import {
  getFormDataListAction,
  getFormDetailsAction
} from "../../Action/admin.action";
import "react-toastify/dist/ReactToastify.css";
import { decryptData } from './decrypt';
import config from "../../config/config";
import ReactToPrint, { useReactToPrint } from 'react-to-print';

const EditFormData = () => {
  let { formId, recordId, name } = useParams();
  const [formList, setFormData] = useState({});
  const [formDetails, setFormDetails] = useState([]);
  const [reason, setReason] = useState('');
  const [reasonStatus, setReasonStatus] = useState('');
  const allDataRef = useRef();
  const [staffFormEditPermission, setStaffFormEditPermission] = useState();

  useEffect(() => {
    getFormDataListAPI();
    getFormDetailsAPI();
  }, []);

  const printAllData = useReactToPrint({
    content: () => allDataRef.current,
  });

  let getFormDetailsAPI = async () => {
    let res = await getFormDetailsAction({ id: formId });
    if (res.success) {
      setFormDetails(decryptData(res.data));
      setStaffFormEditPermission(decryptData(res.staffFormEditPermission));
    }
  };

  let getFormDataListAPI = async () => {
    let res = await getFormDataListAction({ id: formId, recordId: recordId });
    if (res.success) {
      let data = decryptData(res.data?.records)
      localStorage.setItem('mrnNumber', data[0].mrnNumber);
      setFormData(data[0]);
      setReason(decryptData(res.data.reasonOfRecord))
      setReasonStatus(decryptData(res.data.reasonStatus))
    }
  };

  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        {/* <Sidebar /> */}
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={3} md={3} sm={3}> <div className="form-preview-container">
                <AssignedFormSidebar />
              </div></Col>
              <Col lg={9} md={9} sm={9}> <div className="form-preview-container" id="example-collapse-text">
                <h2>{name} Record Details</h2>
                <Link to={formId == 3 || formId == 4 || formId == 5 ? `${config.baseUrl}staffDashboard` : `${config.baseUrl}viewTreatment/${formId}/${recordId}/${name}`} >
                  <span style={{ float: 'right', marginTop: '-15px' }}>Back</span>
                </Link>
                <ul className="list-group">
                  {formDetails.map((fields, index) => (
                    <li key={index} className="list-group-item d-flex flex-row ">
                      <div className="w-50"><strong>{fields.fieldName}:</strong></div>
                      {fields.fieldtype == 'file' ?
                        <>
                          <img src={`${formList[fields.id]}`} height="150px" width="200px" />
                        </>
                        :
                        fields.fieldtype == 'multipleValue' ?
                          <>
                            <ul>
                              {formList[fields.id] &&
                                JSON.parse(formList[fields.id]).map((item, index) => (
                                  <li key={index}>{item}</li>
                                ))
                              }
                            </ul>
                          </>
                          :
                          formList[fields.id]
                      }

                    </li>
                  ))}

                  {reason &&
                    <li className="list-group-item">
                      {reasonStatus == 2 ?
                        <strong>Invalidate:</strong>
                        :
                        reasonStatus == 3 ?
                          <strong>Addendum:</strong>
                          :
                          reasonStatus == 6 ?
                            <strong>Review/Acknowledgement:</strong>
                            : 'Reason'
                      }
                      {reason}
                    </li>
                  }
                  {staffFormEditPermission?.isEdit == 1 &&
                    <div className="row">
                      <div className="col-md-3">
                        <br />
                        <Link to={`${config.baseUrl}editForm-submission/${formId}/${recordId}/${name}`}>
                          <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print'>Modify</button>
                        </Link>
                      </div>
                    </div>
                  }
                </ul>
              </div></Col>
              {formId == 3 &&
                <Col className='' style={{ marginTop: "30px" }}>
                  <div className="form-preview-container">
                    <div className="printStickerBtn">
                      <ReactToPrint
                        trigger={() => <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print '>Print</button>}
                        content={printAllData}
                      />
                    </div>
                    <Row>
                      <Col md={12}>
                        <div className="p-3 mb-4" style={{ border: '1px solid #ccc', borderRadius: '5px' }} ref={allDataRef}>
                          <h5 className="mb-3"><b>Sticker</b></h5>
                          <Row>
                            <Col md={6} className="mb-3">
                              <strong>MRN</strong>: {formList.mrnNumber}
                            </Col>
                            <Col md={6} className="mb-3">
                              <strong>{formDetails[1]?.fieldName}</strong>: {formList[16]} <strong>{formDetails[3]?.fieldName}</strong>: {formList[18]}
                            </Col>
                            <Col md={6} className="mb-3">
                              <strong>{formDetails[12]?.fieldName}</strong>: {formList[21]}
                            </Col>
                            <Col md={6} className="mb-3">
                              <strong>{formDetails[11]?.fieldName}</strong>: {formList[20]}
                            </Col>
                            <Col md={6} className="mb-3">
                              <strong>{formDetails[13]?.fieldName}</strong>: {formList[22]}
                            </Col>
                            <Col md={6} className="mb-3">
                              <strong>Physician</strong>: {formList[43]}
                            </Col>
                            <Col md={6} className="mb-3">
                              <strong>{formDetails[5]?.fieldName}</strong>: {formList.emrNumber}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              }
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default EditFormData;
