import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Modal, Form, Tabs, Tab } from "react-bootstrap";
import "../componentCss/webform.css";
import "../componentCss/sidebar.css";
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import config from "../../config/config";
import DataTable from "react-data-table-component";
import {
  getStaffListAction,
  getFormListWithPermissionAction,
  updateFormsPermissionAction,
  getPatientListAction,
  assignPatientAction,
  getAssignedPatientListAction,
  updateStaffStatusAction,
  getStaffActivityLogsListAction
} from "../../Action/admin.action";
import Swal from "sweetalert2";

import ReactToPrint, { useReactToPrint } from "react-to-print";
import { MdEdit } from "react-icons/md";
import { AiOutlineCalendar } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { BsPersonAdd } from "react-icons/bs";
import { IoPerson, IoPrintSharp } from "react-icons/io5";
import { decryptData } from './decrypt';
import { BsToggleOn, BsToggleOff } from "react-icons/bs";

const StaffManagement = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isAccessAllPatient, setIsAccessAllPatient] = useState(0);
  const [staffList, setStaffList] = useState([]);
  const [patientFilterData, setPatientFilterData] = useState({ 'name': '', 'location': '' });
  const [patientList, setPatientList] = useState([]);
  const [patientListByFilter, setPatientListByFilter] = useState([]);
  const [formList, setFormList] = useState([]);
  const [isModelOpen, setIsOpen] = useState(false);
  const [staffId, setStaffId] = useState(0);
  const [isLaoding, setisLaoding] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isModelView, setIsModelView] = useState(false);
  const [isModelAssignPatient, setIsModelAssignPatient] = useState(false);
  const [viewData, setViewData] = useState({});
  const [staffActivityLogs, setStaffActivityLogs] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [details, setDetails] = useState({ fromDate: '', toDate: '', status: 'all', name: '' });
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const allDataRef = useRef();
  const singleRowRef = useRef();

  const handlePageChange = (page) => {
    setCurrentPage(page - 1);
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (value == 'all') {
      const ids = patientListByFilter.map(item => item.recordId);
      if (selectedOptions.length === ids.length) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions(ids);
      }
    } else {
      setSelectedOptions(prev =>
        prev.includes(value) ? prev.filter(option => option !== value) : [...prev, value]
      );
    }
  };

  // const handleCheckboxChangeForAllPatient = (event) => {
  //   const value = event.target.value;
  //   setIsAccessAllPatient(isAccessAllPatient == 1 ? 0 : 1)
  // };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
  };
  useEffect(() => {
    getStaffListAPI();
  }, []);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredFormList = formList.filter((data) =>
    data.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    setSelectedRow(null);
  }, [currentPage]);


  const staffActivityLogsAPI = async (id) => {
    let res = await getStaffActivityLogsListAction({ id: id });
    if (res.success) {
      let data = decryptData(res.data)
      setStaffActivityLogs(data)
    } else {
      setStaffActivityLogs([])
    }
  }

  let handleChange = (permissionType, formId) => {
    setFormList((prevForms) => {
      return prevForms.map((form) => {
        if (form.id === formId) {
          if (permissionType == "isAccess") {
            return {
              ...form,
              isAccess: !form.isAccess,
            };
          } else if (permissionType == "isEdit") {
            return {
              ...form,
              isEdit: !form.isEdit,
            };
          } else if (permissionType == "isDelete") {
            return {
              ...form,
              isDelete: !form.isDelete,
            };
          }
        }
        return form;
      });
    });
  };


  const deleteForm = async (data) => {
    let msg = ``;
    let status = 0;
    if (data.isActive == 0) {
      msg = `You want to active this staff?`
      status = 1
    } else {
      msg = `You want to deactive this staff?`
      status = 0
    }

    Swal.fire({
      title: "Are you sure?",
      text: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await updateStaffStatusAction({ 'id': data.id, 'status': status });
        if (res.success) {
          getStaffListAPI();
          toast.success(res.msg);
        } else {
          toast.error(res.msg);
        }
      }
    });
  };


  const handleClose = () => setIsOpen(false);
  const handleOpen = (id) => {
    setStaffId(id);
    getFormListAPI(id);
    setIsOpen(true);
  };

  const handleOpenEditView = (id) => {
    setStaffId(id);
    getFormListAPI(id);
  };

  const handleViewClose = () => setIsModelView(false);
  const handleOpenView = (data) => {
    setViewData(data);
    staffActivityLogsAPI(data.id)
    setIsModelView(true);
    handleOpenEditView(data.id);
  };

  const handleOpenAssignPatient = async (data) => {
    let res = await getPatientListAction({ 'id': 1, 'staffId': data.id });
    if (res.success) {
      let data = decryptData(res.data?.records)
      setPatientList(data);
      setPatientListByFilter(data)
    } else {
      setPatientList([]);
      setPatientListByFilter([])
    }

    let assignedPatientList = await getAssignedPatientListAction({ 'staffId': data.id });
    if (assignedPatientList.success) {
      let data = decryptData(assignedPatientList.data)
      let recordIds = data.map(item => item.recordId.toString());
      setSelectedOptions(recordIds);
    }

    setViewData(data);
    setIsModelAssignPatient(true);
    setIsAccessAllPatient(data.isAccessAllPatient)
  };
  const handleViewCloseAssignPatient = () => {
    setIsModelAssignPatient(false);
    setSelectedOptions([]);
  }

  let getFormListAPI = async (userId) => {
    let res = await getFormListWithPermissionAction({ userId: userId });
    if (res.success) {
      setFormList(decryptData(res.data));
    }
  };

  let getStaffListAPI = async () => {

    if (details.fromDate && !details.toDate) {
      toast.error('Please select to date');
      return
    }

    if (!details.fromDate && details.toDate) {
      toast.error('Please select from date');
      return
    }

    let res = await getStaffListAction(details);
    setIsLoading(false);
    if (res.success) {
      let originalText = []
      if (res.data != "") {
        originalText = decryptData(res.data)
      }
      setStaffList(originalText);
    }
  };

  let getStaffListAPIForFilter = async () => {
    window.location.reload();
  };

  const searchByNameHandler = (e) => {
    const { name, value } = e.target;
    setDetails((old) => {
      return { ...old, [name]: value };
    });
  };

  const searchPatientHandler = (e) => {
    const { name, value } = e.target;
    setPatientFilterData((old) => {
      return { ...old, [name]: value };
    });
  };

  let submitPermissionForm = async (e) => {
    e.preventDefault();
    setisLaoding(1);
    let res = await updateFormsPermissionAction({
      userId: staffId,
      formsPermission: formList,
    });
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        setisLaoding(0);
        setIsOpen(false);
      }, 2000);
    } else {
      toast.error(res.msg);
    }
  };

  let assignPatientSubmit = async (e) => {
    e.preventDefault();
    let res = await assignPatientAction({ staffId: viewData.id, patientIds: selectedOptions, 'isAccessAllPatient': isAccessAllPatient });
    if (res.success) {
      toast.success(res.msg);
      setIsModelAssignPatient(false);
      setSelectedOptions([]);
    } else {
      toast.error(res.msg);
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index, column, id) => {
        return index + 1 + currentPage * rowsPerPage;
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
      width: "15%"
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "30%"
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobileNumber,
      sortable: true,
      width: "12%"
    },
    {
      name: 'Roles',
      cell: (row) => (
        <>
          {row.roles && row.roles.map((role, index) => (
            <>
              {index + 1}. {role} <br />
            </>
          ))}

        </>
      ),
      sortable: true,
      width: '20%',
    },
    {
      name: "Status",
      selector: (row) =>
        row.isActive == 1 ? (
          <>
            <div style={{ width: "auto" }}>
              <p className="text-primary mb-0">Active</p>
            </div>
          </>
        ) : (
          <>
            <div style={{ width: "auto" }}>
              <span className="text-danger">Inactive</span>
            </div>
          </>
        ),
      sortable: true,
      width: "10%"
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <div style={{ display: "none", flexDirection: "row", }}>
            <Link to={`${config.baseUrl}edit-staff/${row.id}`} >
              <button className="px-3 py-2 me-2 btn-sm btn-secondary-btn3  no-print  " style={{ fontSize: 14 }} >
                Edit
              </button>
            </Link>

            <Link onClick={() => handleOpen(row.id)}>
              <button className="px-3 py-2 me-2 btn-sm btn-secondary-btn3  no-print" style={{ fontSize: 14 }}>
                Roles
              </button>
            </Link>

            <Link onClick={() => { handleOpenView(row) }}
            >
              <button className="px-3 py-2 btn-sm btn-secondary-btn3  no-print" style={{ fontSize: 14 }}>
                View
              </button>
            </Link>
          </div>
          <Link to={`${config.baseUrl}edit-staff/${row.id}`} title="Edit">
            <span ><MdEdit fill='#434544' size={20} /></span>
          </Link>
          &nbsp;
          &nbsp;
          <Link onClick={() => handleOpenView(row)} title="View Details ">
            <FaEye fill='#434544' size={20} />

          </Link>
          &nbsp;
          &nbsp;
          <span style={{ cursor: 'pointer' }} onClick={() => deleteForm(row)}>
            {row.isActive == 1 ?
              <BsToggleOn fill='#434544' title="Active" size={24} />
              :
              <BsToggleOff fill='#434544' title="Deactive" size={24} />
            }
          </span>
          &nbsp;
          {/* &nbsp; */}
          {/* <Link onClick={() => handleOpen(row.id)} title="Role">
            <IoPerson fill='#434544' size={20} />
          </Link> */}
          {/* &nbsp; */}
          <span title="Assign Patient" style={{ cursor: 'pointer' }} onClick={() => handleOpenAssignPatient(row)}> <BsPersonAdd fill='#434544' size={20} /></span>
        </>
      ),
      sortable: true,
      width: "20%"

    },
  ];

  const printColumns = [
    {
      name: "#",
      cell: (row, index, column, id) => {
        return index + 1 + currentPage * 10;
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobileNumber,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.userGroup,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.isActive == 1 ? (
          <p className="text-primary">Active</p>
        ) : (
          <span className="text-danger">Inactive</span>
        ),
      sortable: true,
    },
  ];

  const printAllData = useReactToPrint({
    content: () => allDataRef.current,
  });

  const handlePrint = (row) => {
    setSelectedRow(row);
  };

  const printSingleRow = useReactToPrint({
    content: () => singleRowRef.current,
  });

  useEffect(() => {
    if (selectedRow) {
      printSingleRow();
    }
    setSelectedRow(null);
  }, [selectedRow, printSingleRow]);

  const resetLocationFilter = () => {
    setPatientFilterData({ 'name': '', 'location': '' });
    setPatientListByFilter(patientList)
  }

  const searchPatientByLocation = () => {
    let allPatient = patientList;
    if (patientFilterData.location) {
      const patientLists = allPatient.filter(item =>
        item[267] &&
        item[267].toLowerCase().includes(patientFilterData.location.toLowerCase())
      );
      allPatient = patientLists;
    } if (patientFilterData.name) {
      const patientLists = allPatient.filter(item =>
        item[1] &&
        item[1].toLowerCase().includes(patientFilterData.name.toLowerCase())
      );
      allPatient = patientLists;
    }
    if (!patientFilterData.location && !patientFilterData.name) {
      toast.error('Please enter location or name')
    }
    setPatientListByFilter(allPatient)
  }

  return (
    <>
      {/* <Dashboardheader />
      <Toaster /> */}
      {/* <div className="wrapper">
        <Sidebar /> */}
      <div id="content">
        <Container fluid className="p-4">
          <Row>
            <Col lg={12} md={12} sm={12} className="">
              <div className="innerhead mb-4">
                <h5 className="headtitle">User Management</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} className="mt-3">
              <div style={{ float: "right" }}>
                <Link to={`${config.baseUrl}add-staff`}>
                  <button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4">
                    Add+
                  </button>

                </Link>
              </div>
              <div className="">
                <div className="row">
                  <div className="col-md-2">
                    <label for="name">Search By:</label>&nbsp;
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      onChange={searchByNameHandler}
                    />
                  </div>

                  <div className="col-md-2">
                    <label for="cars">Search By Status</label> &nbsp; &nbsp;
                    <select
                      name="status"
                      id="cars"
                      className="form-control"
                      onChange={searchByNameHandler}
                    >
                      <option value="all">All</option>
                      <option value="1">Active</option>
                      <option value="0">In active</option>
                    </select>
                  </div>

                  <div className="col-md-2">
                    <label for="name">From:</label>&nbsp;
                    <input
                      type="date"
                      id="name"
                      name="fromDate"
                      className="form-control"
                      onChange={searchByNameHandler}
                    />
                  </div>

                  <div className="col-md-2">
                    <label for="name">To:</label>&nbsp;
                    <input
                      type="date"
                      id="name"
                      name="toDate"
                      className="form-control"
                      onChange={searchByNameHandler}
                    />
                  </div>

                  <div className="col-md-4">
                    <button
                      className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4"
                      onClick={getStaffListAPIForFilter}
                    >
                      Reset
                    </button>
                    &nbsp;
                    <button
                      className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4"
                      onClick={getStaffListAPI}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <br />

              <ReactToPrint
                trigger={() => <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print'>Print</button>}
                content={printAllData}
              />
              <div className="maintable table-responsive">
                <h5 className="">Users List </h5>
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{ width: "40px" }}
                      src="assets/images/loading.gif"
                    />
                  </div>
                ) : (
                  <DataTable
                    columns={columns}
                    data={staffList}
                    pagination
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[10, 15, 25, 50, 100]}
                    paginationComponentOptions={{
                      rowsPerPageText: 'Row per page:',
                      rangeSeparatorText: 'out of',
                    }}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                  />

                )}
              </div>
              {/*------------------------------- Section For Showing All Data For Print--------------------------------------------------- */}

              <div style={{ display: 'none' }}>
                <div ref={allDataRef}>
                  <center>
                    <h2 className=''> User Management List</h2>
                  </center>
                  <DataTable
                    columns={printColumns}
                    data={staffList}
                    pagination={false}
                  />
                </div>
              </div>
              {/*------------------------------- Section For Showing Single Data For Print--------------------------------------------------- */}

              <div style={{ display: 'none' }}>
                <div className="card-body" ref={singleRowRef} style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '10px', margin: '20px', fontFamily: 'Arial, sans-serif' }}>
                  {selectedRow && (
                    <div style={{ textAlign: 'left', lineHeight: '1.5' }}>
                      <h2 className="card-title" style={{ textAlign: 'center', marginBottom: '20px' }}>User Management List</h2>
                      <p><strong>ID:</strong> {selectedRow.id}</p>
                      <p><strong>First Name:</strong> {selectedRow.firstName}</p>
                      <p><strong>Last Name:</strong> {selectedRow.lastName}</p>
                      <p><strong>City:</strong> {selectedRow.city}</p>
                      <p><strong>Address:</strong> {selectedRow.address}</p>
                      <p><strong>Designation:</strong> {selectedRow.designation}</p>
                      <p><strong>Employee Id:</strong> {selectedRow.employeeId}</p>
                      <p><strong>Gender:</strong> {selectedRow.gender}</p>
                      <p><strong>Date Of Joining:</strong> {selectedRow.DateOfJoining}</p>
                      <p><strong>Licence Number:</strong> {selectedRow.licenceNumber}</p>
                      <p><strong>Mobile Number:</strong> {selectedRow.mobileNumber}</p>
                      <p><strong>User Group:</strong> {selectedRow.userGroup}</p>
                      <p><strong>Image:</strong>
                        {selectedRow.photo ? (
                          <>
                            <img width="100" src={selectedRow.photo}></img>
                            <br />
                          </>
                        ) : (
                          ""
                        )}</p>
                      <p><strong>Signature:</strong>
                        {selectedRow.signature ? (
                          <>
                            <img width="100" src={selectedRow.signature}></img>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                      <p><strong>Stamp Image:</strong>
                        {selectedRow.stampImage ? (
                          <>
                            <img width="100" src={selectedRow.stampImage}></img>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                      <p><strong>Status:</strong> {selectedRow.status == 1 ? 'Active' : 'Inactive'}</p>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>


          <Modal
            show={isModelOpen}
            onHide={() => handleClose(false)}
            dialogClassName="modal-90w modal-right"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <b>Permissions</b>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-4">
                  <div className="col-md-6">
                    <Form.Label>
                      <b> Form Name </b>
                    </Form.Label>{" "}
                    <br />
                  </div>

                  <div className="col-md-2">
                    <Form.Label>
                      <b>Access</b>
                    </Form.Label>
                  </div>

                  <div className="col-md-2">
                    <Form.Label>
                      <b> Edit </b>
                    </Form.Label>
                  </div>

                  <div className="col-md-2">
                    <Form.Label>
                      {" "}
                      <b> Delete </b>
                    </Form.Label>
                  </div>
                </Row>
                <hr />
                <Row className="mb-4">
                  <div className="col-md-12">
                    <Form.Control
                      type="text"
                      placeholder="Search Form Name"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </Row>
                {filteredFormList.length > 0
                  ? filteredFormList.map((data) => (
                    <>
                      <Row className="mb-4">
                        <div className="col-md-6">{data.name}</div>

                        <div className="col-md-2">
                          <input
                            type="checkbox"
                            name="isAccess"
                            onChange={() =>
                              handleChange("isAccess", data.id)
                            }
                            checked={data.isAccess == 1 ? "checked" : ""}
                          />
                        </div>

                        <div className="col-md-2">
                          <input
                            type="checkbox"
                            name="isEdit"
                            onChange={() => handleChange("isEdit", data.id)}
                            checked={data.isEdit == 1 ? "checked" : ""}
                          />
                        </div>

                        <div className="col-md-2">
                          <input
                            type="checkbox"
                            name="isDelete"
                            onChange={() =>
                              handleChange("isDelete", data.id)
                            }
                            checked={data.isDelete == 1 ? "checked" : ""}
                          />
                        </div>
                      </Row>
                      <hr />
                    </>
                  ))
                  : <p style={{ textAlign: "center" }}>No Data Found</p>}

                <Row className="mb-4">
                  <Form.Group as={Col}>
                    {isLaoding ? (
                      <>
                        <button
                          disabled
                          className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                        >
                          Loading{" "}
                          <img
                            height="20"
                            width="20px"
                            src="assets/images/loading.gif"
                          />
                        </button>
                      </>
                    ) : (
                      <button
                        type="submit"
                        onClick={submitPermissionForm}
                        className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                      >
                        Submit
                      </button>
                    )}
                  </Form.Group>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>

          {/* Assign form to staff */}
          <Modal
            show={isModelView}
            onHide={() => handleViewClose(false)}
            dialogClassName="modal-90w modal-right modal-lg"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <b>View Staff Details</b>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs defaultActiveKey="basicInfo" id="uncontrolled-tab-example" className="mb-3">
                {/* Tab 1: Basic Information */}
                <Tab eventKey="basicInfo" title="Basic Information">
                  <ul className="list-group">
                    <li className="list-group-item d-flex flex-row " >
                      <div className="w-50">
                        <strong className="staffView w-50">First Name:</strong>{" "}
                      </div>

                      {viewData?.namePrefix}{" "}
                      {viewData?.firstName ? viewData?.firstName : "NA"}
                    </li>
                    <li className="list-group-item d-flex flex-row "  >
                      <div className="w-50">
                        <strong className="staffView">Middle Name:</strong>{" "}
                      </div>

                      {viewData?.middleName ? viewData?.middleName : "NA"}
                    </li>
                    <li className="list-group-item d-flex flex-row">

                      <div className="w-50">
                        <strong className="staffView">Last Name:</strong>{" "}
                      </div>

                      {viewData?.lastName ? viewData?.lastName : "NA"}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">Employee ID:</strong>{" "}
                      </div>

                      {viewData?.employeeId ? viewData?.employeeId : "NA"}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">Email:</strong>{" "}
                      </div>

                      {viewData?.email ? viewData?.email : "NA"}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">Mobile Number:</strong>{" "}
                      </div>

                      {viewData?.mobileNumber ? viewData?.mobileNumber : "NA"}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">City:</strong>{" "}
                      </div>

                      {viewData?.city ? viewData?.city : "NA"}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">Address:</strong>{" "}
                      </div>

                      {viewData?.address ? viewData?.address : "NA"}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">State:</strong>{" "}
                      </div>
                      {viewData?.state ? viewData?.state : "NA"}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">Emirates ID number:</strong>{" "}
                      </div>
                      {viewData?.emiratesIdNumber
                        ? viewData?.emiratesIdNumber
                        : "NA"}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">Gender:</strong>{" "}
                      </div>
                      {viewData?.gender == 1
                        ? "Male"
                        : viewData?.gender == 2
                          ? "Female"
                          : "Other"}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">Designation:</strong>{" "}
                      </div>
                      {viewData?.designation ? viewData?.designation : "NA"}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">Role:</strong>{" "}
                      </div>

                      {viewData?.userGroup ? viewData?.userGroup : "NA"}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">Stamp Image:</strong>
                      </div>
                      {viewData.stampImage ? (
                        <>
                          <img width="100" src={viewData.stampImage}></img>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">Photo:</strong>
                      </div>
                      {viewData.photo ? (
                        <>
                          <img width="100" src={viewData.photo}></img>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">Signature:</strong>
                      </div>
                      {viewData.signature ? (
                        <>
                          <img width="100" src={viewData.signature}></img>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">Date of joining:</strong>
                      </div>
                      {viewData?.DateOfJoining ? viewData?.DateOfJoining : "NA"}
                    </li>

                    <li className="list-group-item d-flex flex-row">
                      <div className="w-50">
                        <strong className="staffView">License Number:</strong>{" "}
                      </div>

                      {viewData?.licenceNumber ? viewData?.licenceNumber : "NA"}
                    </li>
                  </ul>
                </Tab>
                <Tab eventKey="logHistory" title="Log History">
                  <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Updated By</th>
                          <th>Status</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {staffActivityLogs && staffActivityLogs.length > 0 ? (
                          staffActivityLogs.map((log, index) => (
                            <tr key={index}>
                              <td>{log.firstName + ' ' + log.middleName + ' ' + log.lastName}</td>
                              <td>{log.status == 0 ?
                                <p style={{ color: "green" }}>Active</p> :
                                <p style={{ color: "red" }}>Deactive</p>

                              }</td>
                              <td>{log.datetime}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" style={{ textAlign: "center" }}>
                              No log history available.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Tab>

              </Tabs>
            </Modal.Body>
          </Modal>

          {/* Assign patient to staff */}
          <Modal
            show={isModelAssignPatient}
            onHide={() => handleViewCloseAssignPatient(false)}
            dialogClassName="modal-90w modal-right modal-lg"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <b>Assign Patient</b>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <div className="">
                <div className="row">
                  <div className="col-md-4">
                    <label for="name">Patient Location:</label>&nbsp;
                    <input
                      type="text"
                      name="location"
                      className="form-control"
                      placeholder="Enter Location..."
                      value={patientFilterData.location}
                      onChange={searchPatientHandler}
                    />
                  </div>

                  <div className="col-md-4">
                    <label for="name">Patient Name:</label>&nbsp;
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter Name..."
                      value={patientFilterData.name}
                      onChange={searchPatientHandler}
                    />
                  </div>

                  <div className="col-md-4">
                    <button
                      className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4"
                      onClick={resetLocationFilter}
                    >
                      Reset
                    </button>
                    &nbsp;
                    <button
                      className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4"
                      onClick={searchPatientByLocation}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>

              <ul className="list-group">
                <li className="list-group-item d-flex flex-row " >
                  <div className="w-50">
                    <strong className="staffView w-50">Staff Name:</strong>{" "}
                  </div>

                  {viewData?.namePrefix}{" "}
                  {viewData?.firstName ? viewData?.firstName : "NA"}
                </li>

                {/* <li className="list-group-item d-flex flex-row " >
                  <div className="w-50">
                    <strong className="staffView w-50">Is access all patient:</strong>
                  </div>
                  <input
                    type="checkbox"
                    value="1"
                    checked={isAccessAllPatient}
                    onChange={handleCheckboxChangeForAllPatient}
                  />
                </li> */}

                {isAccessAllPatient == 0 &&
                  <>
                    <li className="list-group-item d-flex flex-row " >
                      <div className="w-50">
                        <strong className="staffView w-50">Patient List:</strong>{" "}
                      </div>
                      <ul style={{ listStyleType: 'none', padding: 0, height: '300px', overflowX: 'auto' }}>
                        {patientListByFilter.length > 0 ?
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                value='all'
                                checked={selectedOptions.length === patientList.length}
                                onChange={handleCheckboxChange}
                              />
                              &nbsp; Select All
                            </label>
                          </li> : "No Patient found"}
                        {patientListByFilter.map(data => (
                          <li key={data.recordId}>
                            <label>
                              <input
                                type="checkbox"
                                value={data.recordId}
                                checked={selectedOptions.includes(data.recordId)}
                                onChange={handleCheckboxChange}
                              />
                              &nbsp; {`${data[1] ? data[1] : ''} ${data[2] ? data[2] : ''}(${data[1929] ? data[1929] : 'NA'})`}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </>
                }
                <li className="list-group-item d-flex flex-row " >
                  <div className="w-50">
                    <button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6" onClick={assignPatientSubmit}>Assign</button>
                  </div>
                </li>
              </ul>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
      {/* </div> */}
    </>
  );
};

export default StaffManagement;
